import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Faq from "../components/faq-components/Faq";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import FaqContact from "../components/faq-components/FaqContact";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Layout from "../components/layout";

const faqPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        title="FAQ"
        description="pertanyaan yang paling sering ditanyakan kepada kami"
      />
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <PopupImage/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="FAQ" />
            </DrawerProvider>
          </Sticky>
          <Faq />
          <FaqContact />
          <Footer />
          <ButtonContact/>
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default faqPage;
