import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Icon } from "react-icons-kit";
import { thinDown } from "react-icons-kit/entypo/thinDown";
import { thinRight } from "react-icons-kit/entypo/thinRight";

import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "@common/src/components/Accordion";

import SectionWrapper, { ContentWrapper } from "./faq.style";

const Faq = () => {
  const Data = useStaticQuery(graphql`
    query {
      faqs: allContentfulFaq(sort: { fields: faqID, order: ASC }, filter: {}) {
        nodes {
          title
          image {
            gatsbyImageData
          }
        }
      }
      details: allContentfulFaqDetails(
        sort: { fields: faqDetailID, order: ASC }
      ) {
        nodes {
          faqTitle {
            title
          }
          title
          description {
            raw
          }
        }
      }
    }
  `);

  const {
    faqs: { nodes: data },
    details: { nodes: details },
  } = Data;

  const options = {
    renderMark: {},
    renderNode: {},
  };

  return (
    <>
      {data.map((faq, index) => (
        <SectionWrapper id="feature">
          <Container>
            <ContentWrapper
              key={`faq-${index}`}
              className={faq.title === "SkinGEN-ME" ? "reverted" : ""}
            >
              <Fade left delay={100}>
                <div className="image">
                  <GatsbyImage image={getImage(faq.image)} />
                </div>
              </Fade>
              <Fade right delay={200}>
                <div className="content">
                  <Heading content={faq.title} />
                  <Accordion>
                    <Fragment>
                      {details.map(
                        (item, index) =>
                          item.faqTitle.title === faq.title && (
                            <AccordionItem key={index}>
                              <Fragment>
                                <AccordionTitle>
                                  <Fragment>
                                    <Heading as="h4" content={item.title} />
                                    <IconWrapper>
                                      <OpenIcon>
                                        <Icon icon={thinRight} size={18} />
                                      </OpenIcon>
                                      <CloseIcon>
                                        <Icon icon={thinDown} size={18} />
                                      </CloseIcon>
                                    </IconWrapper>
                                  </Fragment>
                                </AccordionTitle>
                                <AccordionBody>
                                  <div className="faq-body">
                                    {renderRichText(item.description, options)}
                                  </div>
                                </AccordionBody>
                              </Fragment>
                            </AccordionItem>
                          )
                      )}
                    </Fragment>
                  </Accordion>
                </div>
              </Fade>
            </ContentWrapper>
          </Container>
        </SectionWrapper>
      ))}
    </>
  );
};

export default Faq;
