import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Fade from "react-reveal/Fade";
import Image from "gatsby-image";

import Box from "@common/src/components/Box";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import Button from "@common/src/components/Button";

import FaqContactWrapper, {
  ContactWrapper,
  ContactButton,
} from "./faqContact.style";

const FaqContact = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secContact,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "image/logo-cr-genme.png" }) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <FaqContactWrapper>
      <Box {...sectionWrapper} id="faq_section">
        <Container>
          <Fade up delay={100}>
            <Box {...secTitleWrapper} id="faq_box">
              <Heading
                {...secHeading}
                content="Tidak menemukan jawaban yang Anda cari?"
              />
              <Fade up delay={100}>
                <ContactWrapper>
                  <Image
                    fluid={Data.icon.childImageSharp.fluid}
                    alt="contact icon"
                  />
                  <Text {...secContact} content={`0852 8111 2343`} />
                </ContactWrapper>
                <ContactButton>
                  <Link to={"https://wa.me/+6285281112343"}>
                    <Button title="Kontak kami" />
                  </Link>
                </ContactButton>
              </Fade>
            </Box>
          </Fade>
        </Container>
      </Box>
    </FaqContactWrapper>
  );
};

FaqContact.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqContact.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["20px", "30px", "50px", "50px"],
    pb: ["60px", "80px", "80px", "80px"],
  },
  secTitleWrapper: {
    mb: ["55px", "65px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#2aa275",
    mb: "5px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["22px", "24px", "27px"],
    fontWeight: "600",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "20px",
    lineHeight: ["42px", "40px", "50px", "60px"],
  },
  secContact: {
    as: "span",
    textAlign: "center",
    fontSize: ["32px", "40px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  col: {
    width: ["100%", "100%", "75%", "75%"],
  },
  title: {
    fontSize: ["16px", "19px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: 0,
  },
  description: {
    fontSize: "15px",
    color: "textColor",
    lineHeight: "1.75",
    mb: 0,
  },
  buttonWrapper: {
    mt: "50px",
    flexBox: true,
    justifyContent: "center",
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: ["22px"],
    pr: ["22px"],
    colors: "secondaryWithBg",
    minWidth: "150px",
  },
};

export default FaqContact;
