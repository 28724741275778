import styled from "styled-components";
import { themeGet } from "styled-system";

const FaqContactWrapper = styled.div`
  background-color: ${themeGet("colors.genmeLight")};
  display: flex;
  justify-content: center;
  #faq_section {
    margin-top: 2%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  #faq_box {
    margin-bottom: 0;
  }
  button {
    margin: auto;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 150px;
  }
`;

export const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  button {
    background-color: ${themeGet("colors.genmePrimary")};
  }
`;

export default FaqContactWrapper;
